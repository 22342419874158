import '../style.css';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import ad1 from '../assets/ad1.jpg';
import ad2 from '../assets/ad2.jpg';
import ad3 from '../assets/ad3.jpg';
import bg from '../assets/bg.jpg';
import blanc from '../assets/sauvignon-blanc-bottle.png';
import cab from '../assets/cabernet-sauvignon-bottle.png';
import cookie from 'cookie';
import glenn from '../assets/glenn.jpg';
import logo from '../assets/logo.png';
import {Helmet} from 'react-helmet';

export default function HomePage({serverData}) {
  const [over19, setOver19] = useState(serverData.isOver19);

  return (
    <>
      <Helmet htmlAttributes={{lang: 'en'}} title="Kno-Lo Bev Co.">
        <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
        <meta
          name="description"
          content="The first no/lo wines that are doppelgangers for traditional alcohol-heavy wines"
        />
      </Helmet>
      {!over19 ? (
        <div
          style={{
            height: '100vh',
            display: 'flex'
          }}
        >
          <div
            style={{
              maxWidth: 600,
              padding: 40,
              margin: 'auto',
              textAlign: 'center'
            }}
          >
            <img src={logo} className="logo" alt="Kno-Lo Bev Co. logo" />
            <p
              style={{
                fontSize: 24,
                marginBottom: 32
              }}
            >
              You must be 19 or older to visit our site. Please confirm your age
              before proceeding.
            </p>
            <button
              className="button"
              onClick={() => {
                Cookies.set('19', true);
                setOver19(true);
              }}
            >
              I am 19 or older
            </button>
          </div>
        </div>
      ) : (
        <div className="wrapper">
          <header>
            <img src={logo} className="logo" alt="Kno-Lo Bev Co. logo" />
          </header>
          <div className="hero section">
            <div className="image" style={{aspectRatio: '1440 / 1113'}}>
              <img src={bg} alt="Wine bottles and glasses on a wood table" />
            </div>
            <h2>
              Kno it&apos;s low-alcohol.
              <br />
              Kno it&apos;s low in calories.
            </h2>
          </div>
          <div className="section">
            <h3>Our story</h3>
            <div className="story">
              <div>
                <p>
                  Commencing in 2008, Glenn Broadley, no/lo industry expert was
                  one of the very first manufacturers to have successfully
                  distributed and sold no/lo beverages coast-to-coast throughout
                  North America. Broadley was the co-founder of Hill Street
                  Beverage that created the successful programs MADD Virgin
                  Drinks and Designated Draft, gaining full distribution in both
                  Shoppers Drug Mart as well as Wallgreens and other retailers
                  coast-to-coast. To add, Glenn advised the team at Grüvi which
                  is currently thriving in the marketplace. Glenn&apos;s 40-
                  year career of creating successful brands continued when he
                  and his business partner, Alli Mang, joined forces to create a
                  new company armed with a team of experienced and serious wine
                  professionals; Ontario winemaker Adam Kern, and Sommelier
                  James Oatway. After having sampled everything on the market
                  from “juice-up” to other&apos;s dealcoholized wines, our team
                  found very little satisfaction with the profile of any of
                  these beverages, and set out to refine their own expectations
                  with Kno-Lo Bev Co&apos; brand The Imposter.
                </p>
                <p>
                  Sadly, our advisor, friend and mentor, Glenn Broadley passed
                  away in November 2021 with a long, well fought battle with
                  cancer. Gone far too soon, but we are continuing to carry on
                  his vision and passion with our experience to create the first
                  no/lo wines that are doppelgangers for traditional
                  “alcohol-heavy” wines.
                </p>
              </div>
              <div>
                <div className="image">
                  <img src={glenn} alt="Glenn Broadley" />
                </div>
                <h4 style={{position: 'relative', marginTop: 8}}>
                  Glenn Broadley
                </h4>
              </div>
            </div>
            <a
              className="button"
              href="/THE IMPOSTER PRESENTATION MARCH 2022.pdf"
            >
              View full presentation
            </a>
          </div>
          <div
            className="section"
            style={{
              display: 'grid',
              gap: 40
            }}
          >
            <div className="wine">
              <img src={blanc} alt="Bottle of Sauvignon Blanc wine" />
              <div>
                <h3>California Sauvignon Blanc</h3>
                <p>
                  Your eyes see a traditional straw-coloured wine. On the nose
                  you&apos;ll enjoy hints of crisp yellow apple, pear,
                  gooseberry and pineapple. Your palate detects a well-balanced,
                  complex wine mingling flavours of pineapple, pear and
                  gooseberry. Light bodied and tangy with crisp acidity and a
                  refreshing, lengthy finish. Enjoy with grilled calamari, warm
                  goat&apos;s cheese salad, oysters or sushi. Low in sugar. Low
                  in calories. Made with only natural Ingredients.{' '}
                  <strong>Can you spot The Imposter?</strong>
                </p>
              </div>
            </div>
            <div className="wine">
              <div>
                <h3>California Cabernet Sauvignon</h3>
                <p>
                  Your eyes see a traditional wine. On the nose it&apos;s
                  remarkably complex with notes of dark morello cherry,
                  raspberry and vanilla. Your palate detects a rich,
                  well-balanced, wine with bright acidity and exceptional
                  freshness that gives it a long, elegant finish consisting of
                  ever evolving, persistent flavours. A perfect companion to
                  barbecue short ribs, burgers, or a clever charcuterie board.
                  Low in sugar. Low in calories. Made with only natural
                  Ingredients. <strong>Can you spot The Imposter?</strong>
                </p>
              </div>
              <img src={cab} alt="Bottle of Cabernet Sauvignon wine" />
            </div>
          </div>
          <h3>Ad campaign</h3>
          <p style={{marginBottom: 24, maxWidth: 800}}>
            Have you ever been to a gathering, soft-drink or water in hand, and
            been grilled by everyone there as to “why you&apos;re not drinking?”
            Not anymore. They&apos;re enjoying cocktails, beer and wine—but so
            are you (and no one will be the wiser)...
          </p>
          <div className="ads">
            <img src={ad1} alt="Can you spot The Imposter ad #1" />
            <img src={ad3} alt="Can you spot The Imposter ad #3" />
            <img src={ad2} alt="Can you spot The Imposter ad #2" />
          </div>
          <footer
            style={{
              paddingBlock: 40,
              textAlign: 'center'
            }}
          >
            &copy; Kno-Lo Bev Co.
          </footer>
        </div>
      )}
    </>
  );
}

HomePage.propTypes = {
  serverData: PropTypes.object.isRequired
};

export function getServerData(req) {
  const cookies = cookie.parse(req.headers.get('cookie') || '');
  return {
    props: {
      isOver19: cookies[19] === 'true'
    }
  };
}
